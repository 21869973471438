(function($) {

    $(document).ready(function(){

        // RESPONSIVE NAV
        $('.hamburger').click(function(){
            $(this).toggleClass('is-active');
            $('.navbar').toggleClass('is-opened');
        });

        
        // SMO0TH SCROLLING
        /*$(function() {
            $('a[href*="#"]:not([href="#"])').click(function() {
                if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
                    var target = $(this.hash);
                    target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
                    if (target.length) {
                        $('html, body').animate({
                            scrollTop: target.offset().top
                        }, 700);
                        return false;
                    }
                }
            });
        });*/

    });
})(jQuery);